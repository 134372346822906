import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import "./Life.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundImg from "../../assets/pupi.jpg";

const API_URL = process.env.REACT_APP_API_URL;

const EventImageCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  if (!images || images.length === 0) {
    return null; // Render nothing if there are no images
  }

  const convertBufferToBase64 = (buffer) => {
    const binary = buffer.reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ""
    );
    return `data:image/jpeg;base64,${btoa(binary)}`;
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index}>
          <img
            src={convertBufferToBase64(image.image.data)}
            alt={`Event Image ${index + 1}`}
            className="life-event-image"
          />
        </div>
      ))}
    </Slider>
  );
};

const Life = () => {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    fetchAlbumData();
  }, []);

  const fetchAlbumData = async () => {
    try {
      const response = await axios.get(`${API_URL}/albums`);
      const groupedAlbums = groupByCategory(response.data);
      setAlbums(groupedAlbums);
    } catch (error) {
      console.error("Error fetching album data", error);
    }
  };

  const groupByCategory = (albums) => {
    const categories = {};
    albums.forEach((album) => {
      if (!categories[album.category]) {
        categories[album.category] = [];
      }
      categories[album.category].push(album);
    });
    return categories;
  };

  return (
    <div className="life-main-div">
      <div
        className="contact-container"
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
      >
        <div className="contact-box">
          <div>
            <p>{/* Home / <span>Life</span> */}</p>
            <h1 className="contact-title">LIFE AT ARON</h1>
          </div>
        </div>
      </div>
      <div className="life-herosection">
        <div className="life-hero-contents">
          {/* <h2 className="life-title1">Life At Aron</h2> */}
          <h1 className="life-title2">
            Elevate Engineering experience with
            <span className="span-hero"> Passion</span> and
            <span className="span-hero"> Innovation</span>
          </h1>
          <h3 className="life-title3">
            Welcome to Aron Engineering, where every day is an opportunity to
            thrive, create, and innovate
          </h3>
        </div>
        {/* <div className="life-hero-img-div">
          <img
            className="life-hero-img"
            // src={mainpic}
            alt="Aron Engineering Banner"
          />
        </div> */}
      </div>

      <div className="life-events-section">
        <h2 className="life-events-title">Our Events</h2>
        {Object.keys(albums).map((category, index) => (
          <div key={index} className="life-event">
            <h3 className="life-event-title">{category}</h3>
            <EventImageCarousel images={albums[category]} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Life;
