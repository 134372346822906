import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./herosection.css";
import "./arrow.css";
import "animate.css";
import Img1 from "../../assets/Monasteries-in-Nepal.jpeg";
import Img2 from "../../assets/timber.jpg";
import Img3 from "../../assets/worldmap2.png";
import Arrow from "./Arrow";

import GoToTop from "../GoToTop";

const images = [
  {
    path: Img1,
    itemName: "Image 1",
    title: "Draft your imagination with us!",
    para: "Our dedicated architects provide the best 3D modelling and rendering services for both commercial and residential structures. Be it interior or exterior, we here at Aron integrate your design ideas with our creative thoughts to make your visions a reality.",
  },
  {
    path: Img2,
    itemName: "Image 2",
    title: "Structural Design",
    para: "With innovative and cost-effective structural solutions, our technical team expertise on design of timber, steel and concrete construction and any other structural material standards throughout the industry.",
  },
  {
    path: Img3,
    itemName: "Image 3",
    title: "Collaboration with DEKAT Engineering LLC located in USA.",
    para: "We have collaborated with DEKAT Engineering LLC, a US based engineering firm, to provide consulting services to each other. This symbiotic relationship ensures a seamless exchange of knowledge and resources between the two firms, fostering a commitment to quality and excellence.",
  },
  // Add more image paths and item names here
];

const FullScreenCarousel = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 10000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  // const handleItemClick = (index) => {
  //   setCurrentImage(index);
  // };
  const nextSlide = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -500 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="full-screen-carousel"
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-slide ${
              currentImage === index ? "active" : ""
            }`}
            style={{
              backgroundImage: `url(${image.path})`,
            }}
          >
            <div className="hero-container">
              <div key={index} className="hero-content">
                <div className="hero-title">
                  <h1>
                    <mark className="hero-totle">{image.title}</mark>
                  </h1>
                </div>
                <div className="hero-para">
                  <p>{image.para}</p>
                </div>
                {/* <div className="hero-cta">
                <button>Learn more about {image.itemName}</button>
              </div> */}
              </div>
            </div>
          </div>
        ))}

        <div className="arrow-symbol">
          <Arrow direction="prev" onClick={prevSlide} />
          <Arrow direction="next" onClick={nextSlide} />
        </div>

        {/* Slide show indicator */}

        {/* <ol className="carousel-list">
          {images.map((image, index) => (
            <li
              key={index}
              className={currentImage === index ? "active" : ""}
              onClick={() => handleItemClick(index)}
            >
              {image.itemName}
            </li>
          ))}
        </ol> */}
      </motion.div>
      <GoToTop />
    </>
  );
};

export default FullScreenCarousel;
