import React, { useState } from "react";

const AnnouncementCard = ({ announcement }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  const handleLearnMore = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const imageUrl = `${process.env.REACT_APP_API_URL}/events/${announcement.id}/image`;

  return (
    <div className="announcement-container">
      <div className="announcement-card">
        <div className="announcement-card1">
          <img
            src={imageUrl}
            alt={announcement.heading}
            className="announcement-image"
          />
        </div>
        <div className="announcement-card2">
          <p className="announcement-date">{announcement.date}</p>
          <h3 className="announcement-title">{announcement.heading}</h3>
          <p className="announcement-description">
            {truncateText(announcement.description, 150)}
          </p>
          <button className="learn-more-btn" onClick={handleLearnMore}>
            <span className="circle" aria-hidden="true">
              <span className="icon arrow"></span>
            </span>
            <span className="button-text">Learn More</span>
          </button>
        </div>
      </div>

      {/* Modal Popup */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content1">
              <img
                src={imageUrl}
                alt={announcement.heading}
                className="modal-image"
              />
            </div>
            <div className="modal-content2">
              <h2 className="modal-title">{announcement.heading}</h2>
              <p className="modal-event">Event Description</p>
              <p className="modal-description">{announcement.description}</p>
              <button className="close-btn" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementCard;
