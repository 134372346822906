import React, { useState } from "react";
import "./navsection.css";
import { Link, NavLink } from "react-router-dom";
import AronLogo from "../../assets/aron-logo.png";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const NavSection = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState();

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };
  return (
    <>
      <div className="navbarsection">
        <div className="logo">
          <Link to="/">
            <div className="logo-img">
              <img src={AronLogo} alt="aronlogo" />
            </div>

            <p className="logo-name">ARON ENGINEERING PVT.LTD</p>
          </Link>
        </div>
        <div className="navbar">
          <nav className="nav-items">
            <NavLink to="/">
              <p>HOME</p>
            </NavLink>
            <NavLink to="/portfolio">
              <p>PORTFOLIO</p>
            </NavLink>

            <NavLink to="/services">
              <p>SERVICES</p>
            </NavLink>

            <NavLink to="/teameverest">
              <p>MEET US</p>
            </NavLink>
            <NavLink to="/life">
              <p>LIFE AT ARON</p>
            </NavLink>
            <NavLink to="/announcements">
              <p>ANNOUNCEMENTS</p>
            </NavLink>
            <NavLink to="/contact">
              <p>CONTACT US</p>
            </NavLink>
          </nav>
        </div>
        <div className="navbar-mob">
          {!navOpen ? (
            <div className="nav-close" onClick={() => setNavOpen(true)}>
              <MenuIcon style={{ fontSize: "2.5rem" }} />
            </div>
          ) : (
            <div className="nav-open" onClick={() => setNavOpen(false)}>
              <div className="close-icon">
                <CloseIcon style={{ fontSize: "2.5rem" }} />
              </div>

              <div className="navbar-items">
                <li>
                  <Link to="/">
                    <p
                      className={activeNavItem === "home" ? "active" : ""}
                      onClick={() => handleNavItemClick("home")}
                    >
                      HOME
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/portfolio">
                    <p
                      className={activeNavItem === "portfolio" ? "active" : ""}
                      onClick={() => handleNavItemClick("portfolio")}
                    >
                      PORTFOLIO
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                    <p
                      className={activeNavItem === "services" ? "active" : ""}
                      onClick={() => handleNavItemClick("services")}
                    >
                      SERVICES
                    </p>
                  </Link>
                </li>

                <li>
                  <Link to="/life">
                    <p
                      className={
                        activeNavItem === "teameverest" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("teameverest")}
                    >
                      Life At Aron
                    </p>
                  </Link>
                </li>

                <li>
                  <Link to="/teameverest">
                    <p
                      className={
                        activeNavItem === "teameverest" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("teameverest")}
                    >
                      MEET US
                    </p>
                  </Link>
                </li>

                <li>
                  <Link to="/announcements">
                    <p
                      className={
                        activeNavItem === "announcements" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("announcements")}
                    >
                      ANNOUNCEMENTS
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">CONTACT US</Link>
                  <p
                    className={activeNavItem === "contact" ? "active" : ""}
                    onClick={() => handleNavItemClick("contact")}
                  ></p>
                </li>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavSection;
