import React from "react";
import "./about.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import AboutImg from "../../assets/bg3.jpg";

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation only occurs once when in view
  });
  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
        transition={{ duration: 1 }}
        className="about"
      >
        <div className="about-content">
          <div className="about-content-text">
            <p>
              Aron Engineering is a Nepal based engineering design firm which
              mainly caters to structural design of components, drafting,
              modelling and rendering of structures. Regardless of complexity or
              scale, our dynamic team's professional standards, technical
              excellence, safe, innovative and sustainable engineering solutions
              are crafted to empower your challenging projects.
            </p>
            <p>
              Our collaboration with DEKAT Engineering LLC offers an integrated
              technical team comprised of highly competent engineers and
              architects from both Nepal and the United States ensuring the
              highest-quality, cost-effective services on time which is our
              primary objective.
            </p>
            {/* <p>Learn more about DEKAT.</p> */}
          </div>
          <div className="about-btn">
            <a
              href="https://dekatengineering.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button>Learn more about DEKAT</button>
            </a>

            {/* <button>Construction</button> */}
          </div>
        </div>
        <div className="about-data">
          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Fast Turnaround ⌛</h1>
              <p>
                Our fast turnaround time isn't just a promise; it's a testament
                to our efficiency and dedication.
              </p>
            </div>
          </div>

          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Licensed Professionals 📄</h1>
              <p>
                Experts with hands-on experience familiar with world-wide design
                codes
              </p>
            </div>
          </div>
          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Integrated and cost-effective 💸</h1>
              <p>
                Experience seamless efficiency with our integrated and
                cost-effective services, where efficiency meets affordability to
                elevate design needs.
              </p>
            </div>
          </div>
          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Knowledge sharing platform 💡</h1>
              <p>
                Internships and seminar opportunities to facilitate knowledge
                exchange from team experts
              </p>
            </div>
          </div>
        </div>
        {/* <div className="about-img">
          <div className="img-box">
            <img src={AboutImg} alt="aboutimg" />
          </div>
        </div> */}
      </motion.div>
    </>
  );
};

export default About;
