import React from "react";
import FullScreenCarousel from "./herosection/HeroSection";
import Services from "./services/Services";
// import Projects from "./project/Projects";
import About from "./about/About";
import Quote from "./quote/Quote";
// import AronServices from "./aronservices/AronServices";
import "./Home.css";

const Home = () => {
  return (
    <div className="container">
      <FullScreenCarousel />
      <About />
      <Quote />
      <Services />
      {/* <AronServices /> */}
      {/* <Projects /> */}
    </div>
  );
};

export default Home;
