import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import PortfolioPage from "./portfoliopage/PortfolioPage";
import ServicesPage from "./servicespage/ServicesPage";
import ContactPage from "./contactpage/ContactPage";
import MeetusPage from "./meetuspage/MeetusPage";
import TeamEverest from "./teameverest/TeamEverest";
import CarrierPage from "./carrierpage/CarrierPage";
import TeamElbert from "./teamelbert/TeamElbert";
import Life from "./Life/Life";
import JobDetailsPage from "./carrierpage/JobDetailPage/JobDetailsPage";

const BodySection = () => {
  return (
    <>
      <div className="bodysection">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/portfolio" exact element={<PortfolioPage />} />
          <Route path="/services" exact element={<ServicesPage />} />
          <Route path="/meetus" exact element={<MeetusPage />} />
          <Route path="/teameverest" exact element={<TeamEverest />} />
          <Route path="/teamelbert" exact element={<TeamElbert />} />
          <Route path="/announcements" exact element={<CarrierPage />} />
          <Route path="/contact" exact element={<ContactPage />} />
          <Route path="/life" exact element={<Life />} />
          <Route path="/job/:jobId" exact element={<JobDetailsPage />} />{" "}
          {/* Add the route for JobDetailsPage */}
        </Routes>
      </div>
    </>
  );
};

export default BodySection;
