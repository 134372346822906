import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getCategories = async () => {
  const response = await axios.get(`${API_URL}/categories`);
  return response.data;
};

export const getTeamMembers = async () => {
  const response = await axios.get(`${API_URL}/team`);
  return response.data;
};
export const getAlbumCategoriesWithImages = async () => {
  const response = await axios.get(`${API_URL}/albums`);
  return response.data;
};
