import React from "react";
import { Link } from "react-router-dom";
import "animate.css";
import "./services.css";
import { Data } from "./Data";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation only occurs once when in view
    delay: 200,
  });
  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: [0, 1], y: -500 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -500 }}
        transition={{ duration: 1, type: "tween" }}
        className="services"
      >
        <div className="services-content">
          <div className="services-title">
            <h1>Construction and Structural Engineering Services</h1>
          </div>
          <div className="services-cta">
            <Link to="/services">
              <button>More Services</button>
            </Link>
          </div>
        </div>
        <div className="card">
          {Data.map((item, index) => (
            <div key={index} className="services-card">
              <img src={item.img} alt={item.title} />

              <div className="card-text">
                <h3>{item.title}</h3>
                <Link className="contact-us" to="/contact">
                  Contact Us
                </Link>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default Services;
