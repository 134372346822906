import Img1 from "../../assets/services-img/structuraldesign.png";
import Img2 from "../../assets/services-img/geotechnicaldesign.jpg";
import Img3 from "../../assets/services-img/urbanplanning.jpeg";
import Img4 from "../../assets/services-img/patio-coverdesign.png";

export const Data = [
  {
    img: Img1,
    title: "Structural & Architectural Design ",
  },
  {
    img: Img2,
    title: "Geotechnical Design ",
  },
  {
    img: Img3,
    title: "Urban Planning ",
  },
];
