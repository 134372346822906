// import React, { useState, useEffect } from "react";
// import { getCategories, getTeamMembers } from "../../api";
// import "./teameverest.css";
// import GoToTop from "../GoToTop";
// import BackgroundImg from "../../assets/pupi.jpg";

// const API_URL = process.env.REACT_APP_API_URL;

// const TeamEverest = () => {
//   const [categories, setCategories] = useState([]);
//   const [teamMembers, setTeamMembers] = useState([]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const categoriesData = await getCategories();
//       const teamMembersData = await getTeamMembers();
//       setCategories(categoriesData);
//       setTeamMembers(teamMembersData);
//     } catch (error) {
//       console.error("Error fetching data", error);
//     }
//   };

//   const getCategoryMembers = (categoryName) => {
//     return teamMembers.filter((member) => member.category === categoryName);
//   };

//   return (
//     <>
//       <div className="everest-page">
//         <div
//           className="contact-container"
//           style={{
//             backgroundImage: `url(${BackgroundImg})`,
//             backgroundSize: `cover`,
//             backgroundPosition: `center`,
//           }}
//         >
//           <div className="contact-box">
//             <div>
//               <p>{/* Home / <span>Meet Us</span> */}</p>
//               <h1 className="contact-title">MEET US</h1>
//             </div>
//           </div>
//         </div>

//         <div className="everest-content">
//           {categories.map((category) => (
//             <div key={category.id}>
//               {category.name === "Director" ? (
//                 <div className="managing-director">
//                   {getCategoryMembers(category.name).map((member, index) => (
//                     <div key={index} className="managing-director-content">
//                       <img
//                         src={`${API_URL}/team/${member.id}/image`}
//                         alt={member.fullname}
//                       />
//                       <div className="managing-director-text">
//                         <h1>{member.fullname}</h1>
//                         <h4>{member.designation}</h4>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : null}
//             </div>
//           ))}

//           {/* <div className="team-members-tagline">
//             <h2>Team Members</h2>
//           </div> */}
//           {/* Display the other categories */}
//           {categories.map((category) =>
//             category.name !== "Director" ? (
//               <div key={category.id}>
//                 <div className="team-header"></div>
//                 <div className="members-list">
//                   {getCategoryMembers(category.name).map((member, index) => (
//                     <div key={index} className="members-list-card">
//                       <img
//                         src={`${API_URL}/team/${member.id}/image`}
//                         alt={member.fullname}
//                       />
//                       <div className="members-list-card-text">
//                         <h4>{member.fullname}</h4>
//                         <p>{member.designation}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             ) : null
//           )}
//         </div>
//       </div>
//       <GoToTop />
//     </>
//   );
// };

// export default TeamEverest;

import React, { useState, useEffect } from "react";
import { getCategories, getTeamMembers } from "../../api";
import "./teameverest.css";
import GoToTop from "../GoToTop";
import BackgroundImg from "../../assets/pupi.jpg";

const API_URL = process.env.REACT_APP_API_URL;

const TeamEverest = () => {
  const [categories, setCategories] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const categoriesData = await getCategories();
      const teamMembersData = await getTeamMembers();
      setCategories(categoriesData);
      setTeamMembers(teamMembersData);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryMembers = (categoryName) => {
    return teamMembers.filter((member) => member.category === categoryName);
  };

  if (loading) {
    return (
      <div className="loader">
        <label>Please wait...</label>
        <div className="loading"></div>
      </div>
    );
  }

  return (
    <>
      <div className="everest-page">
        <div
          className="contact-container"
          style={{
            backgroundImage: `url(${BackgroundImg})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
          }}
        >
          <div className="contact-box">
            <div>
              <p>{/* Home / <span>Meet Us</span> */}</p>
              <h1 className="contact-title">MEET US</h1>
            </div>
          </div>
        </div>

        <div className="everest-content">
          {categories.map((category) => (
            <div key={category.id}>
              {category.name === "Director" ? (
                <div className="managing-director">
                  {getCategoryMembers(category.name).map((member, index) => (
                    <div key={index} className="managing-director-content">
                      <img
                        src={`${API_URL}/team/${member.id}/image`}
                        alt={member.fullname}
                      />
                      <div className="managing-director-text">
                        <h1>{member.fullname}</h1>
                        <h4>{member.designation}</h4>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}

          {categories.map((category) =>
            category.name !== "Director" ? (
              <div key={category.id}>
                <div className="team-header"></div>
                <div className="members-list">
                  {getCategoryMembers(category.name).map((member, index) => (
                    <div key={index} className="members-list-card">
                      <img
                        src={`${API_URL}/team/${member.id}/image`}
                        alt={member.fullname}
                      />
                      <div className="members-list-card-text">
                        <h4>{member.fullname}</h4>
                        <p>{member.designation}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
      <GoToTop />
    </>
  );
};

export default TeamEverest;
