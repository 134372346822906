import React from "react";
import "./servicespage.css";
import BackgroundImg from "../../assets/pupi.jpg";
import StructuralDesign from "../../assets/services-img/structuraldesign.png";
import DeckDesign from "../../assets/services-img/deckdesign.png";
import PatioCover from "../../assets/services-img/patio-coverdesign.png";
import TinyHouse from "../../assets/services-img/tinyhome.png";
import CoveredHall from "../../assets/services-img/coveredhall.png";
import Stadium from "../../assets/services-img/stadium.jpg";
import CommercialStructure from "../../assets/services-img/commercialstructure.jpg";
import ResidentialSturcture from "../../assets/services-img/residentialstructure.png";
import PublicBuilding from "../../assets/services-img/publicbuilding.jpg";
import Landscaping from "../../assets/services-img/landscaping.jpg";
import GeoTechnical from "../../assets/services-img/geotechnicaldesign.jpg";
import UrbanPlanning from "../../assets/services-img/urbanplanning.jpeg";
import Transportation from "../../assets/services-img/transportation.jpeg";
import WaterSupply from "../../assets/services-img/watersupply.jpeg";
import ElectricalWorks from "../../assets/services-img/electricalworks.jpeg";
import Sewage from "../../assets/services-img/sewage.jpeg";
import Irrigation from "../../assets/services-img/irrigation.jpeg";
import RemoteSensing from "../../assets/services-img/remotesensing.jpeg";
import Valuation from "../../assets/services-img/valuation.jpeg";
import SoftwareTraining from "../../assets/services-img/softwaretraining.jpeg";
import feasibility from "../../assets/services-img/Feasibilitystudy.webp";
import rivertraining from "../../assets/services-img/rivertraining.jpg";
import GoToTop from "../GoToTop";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ServicesPage = () => {
  return (
    <>
      <div className="services-page">
        <div
          className="services-container"
          style={{
            backgroundImage: `url(${BackgroundImg})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
          }}
        >
          <div className="services-box">
            <div>
              <p>{/* Home / <span>services</span> */}</p>
              <h1>SERVICES </h1>
            </div>
          </div>
        </div>
        <div className="services-page-content">
          <div className="services-content-first">
            <div className="first-img">
              <img src={StructuralDesign} alt="structurald design" />

              <h1>01</h1>
            </div>
            <div className="first-content">
              <h1 className="services-title">
                Structural & Architectural Design
              </h1>
              <p>
                Aron Engineering stands at the forefront of design innovation,
                boasting a dynamic team of structural and geotechnical
                engineers, and architects dedicated to turning vision into
                reality. Our professionals, equipped with the latest skills and
                a commitment to staying ahead of the curve, seamlessly integrate
                cutting-edge design requirements, international standards, and
                state-of-the-art software tools.
              </p>
              <p>
                Beyond structural engineering, we elevate your projects with our
                comprehensive 2D and 3D architectural services. Focused on
                enhancing visualization, we transform design concepts into
                tangible communication through precise 2D plans and immersive 3D
                models. Immerse yourself in the future with our photo-realistic
                renderings, crafted with meticulous attention to artistic
                nuances, lighting dynamics, and spatial intricacies. Our
                walk-throughs and animations provide a multi-perspective
                understanding of your project's spatial essence, while our
                mastery extends to landscaping and master plans for projects of
                all scales.
              </p>
              <p>
                At Aron Engineering, compliance with by-laws and regulations is
                not just a standard; it's an assurance. Our designs are not only
                technologically advanced but also artistically refined,
                supported by a team of skilled personnel who understand the
                pulse of each project.
              </p>
              <p>
                For all-encompassing structural and architectural solutions,
                look no further. Aron Engineering is your partner in
                transforming ideas into concrete realities. Contact us for
                engineering solutions that transcend boundaries.
              </p>
            </div>
          </div>
          <div className="services-content-second">
            <h3>Services Based On Our Location</h3>
            <div className="united-state">
              <h1>UNITED STATES</h1>
              <div className="united-services-list">
                <div className="list-card">
                  <img src={DeckDesign} alt="deck design" />

                  <h4>Deck Design</h4>
                  <p>
                    We cover an all-inclusive design package for all types of
                    timber and steel deck designs based on international codes.
                  </p>
                </div>
                <div className="list-card">
                  <img src={PatioCover} alt="patio cover" />

                  <h4>Pergola & Patio/Deck CoverDeck Design</h4>
                  <p>
                    We design multiple kinds of pergolas, gazebo, deck/roof
                    cover and patio cover including mono-slope, gable and hip
                    roof designs.
                  </p>
                </div>
                <div className="list-card">
                  <img src={TinyHouse} alt="tinyhouse" />

                  <h4>Design of Tiny Homes </h4>
                  <p>
                    We also design tiny wooden houses inclusive of shear wall
                    and foundation design along with seismic and wind resistance
                    checks.
                  </p>
                </div>
              </div>
            </div>
            <div className="united-state">
              <h1>NEPAL</h1>
              <div className="united-services-list">
                <div className="list-card">
                  <img src={CoveredHall} alt="covered hall" />

                  <h4>Multi-purpose covered hall</h4>
                  <p>
                    We create versatile covered halls with global safety
                    standards and innovative functionality.
                  </p>
                </div>
                <div className="list-card">
                  <img src={Stadium} alt="stadium" />

                  <h4>Stadium</h4>
                  <p>
                    Cutting-edge designs for stadiums, prioritizing spectator
                    experience, safety, and modern amenities.
                  </p>
                </div>
                <div className="list-card">
                  <img src={CommercialStructure} alt="commercial structure" />

                  <h4>Commercial Structures</h4>
                  <p>
                    Efficient and modern designs for offices, retail, and
                    industrial spaces.
                  </p>
                </div>
                <div className="list-card">
                  <img src={ResidentialSturcture} alt="residential structure" />

                  <h4>Residential Structures</h4>
                  <p>
                    Tailored designs for comfortable and aesthetic homes,
                    apartments, and communities.
                  </p>
                </div>
                <div className="list-card">
                  <img src={PublicBuilding} alt="public building" />

                  <h4>Public Buildings</h4>
                  <p>
                    Expert designs for schools, libraries, and community
                    centers, emphasizing accessibility and sustainability.
                  </p>
                </div>
                <div className="list-card">
                  <img src={Landscaping} alt="landscaping" />

                  <h4>Landscaping</h4>
                  <p>
                    Comprehensive designs transforming outdoor spaces into
                    visually appealing environments.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-content-third">
            <h1>Other Services</h1>
            <div className="other-services-list">
              <div className="list-card">
                <img src={GeoTechnical} alt="geo technical" />
                <h1>02</h1>
                <h4>Geotechnical/ Geological Design</h4>
              </div>
              <div className="list-card">
                <img src={UrbanPlanning} alt="urban planning" />
                <h1>03</h1>
                <h4>Urban Planning </h4>
              </div>
              <div className="list-card">
                <img src={Transportation} alt="transportation" />
                <h1>04</h1>
                <h4>Transportation</h4>
              </div>

              <div className="list-card">
                <img src={WaterSupply} alt="watersupply" />
                <h1>05</h1>
                <h4>Water Supply & Sanitary Design</h4>
              </div>
              <div className="list-card">
                <img src={ElectricalWorks} alt="electrical works" />
                <h1>06</h1>
                <h4>Electrical Works </h4>
              </div>
              <div className="list-card">
                <img src={Sewage} alt="sewage" />
                <h1>07</h1>
                <h4>Sewerage & Land-fill Site Design</h4>
              </div>
              <div className="list-card">
                <img src={Irrigation} alt="irrigation" />
                <h1>08</h1>
                <h4>Irrigation</h4>
              </div>
              <div className="list-card">
                <img src={RemoteSensing} alt="remote sensing" />
                <h1>09</h1>
                <h4>Remote Sensing based survey and mapping </h4>
              </div>
              <div className="list-card">
                <img src={Valuation} alt="valuation" />
                <h1>10</h1>
                <h4>Valuation </h4>
              </div>
              <div className="list-card">
                <img src={feasibility} alt="feasibility study" />
                <h1>11</h1>
                <h4>Feasibility Study </h4>
              </div>
              <div className="list-card">
                <img src={rivertraining} alt="river training works" />
                <h1>12</h1>
                <h4>River- training works </h4>
              </div>
              <div className="list-card">
                <img src={SoftwareTraining} alt="software training" />
                <h1>13</h1>
                <h4>Software trainings </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </>
  );
};

export default ServicesPage;
